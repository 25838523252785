/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
.awsui_root_cdujn_18ie7_11:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  display: flex;
  flex-direction: row;
  gap: var(--space-scaled-xs-26e2du, 8px);
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
}

.awsui_message-area_cdujn_18ie7_45:not(#\9) {
  display: flex;
  flex-direction: column;
  gap: var(--space-scaled-s-aqzyko, 12px);
  padding: var(--space-scaled-s-aqzyko, 12px);
  border-start-start-radius: var(--border-radius-chat-bubble-qvkt36, 8px);
  border-start-end-radius: var(--border-radius-chat-bubble-qvkt36, 8px);
  border-end-start-radius: var(--border-radius-chat-bubble-qvkt36, 8px);
  border-end-end-radius: var(--border-radius-chat-bubble-qvkt36, 8px);
}
.awsui_message-area_cdujn_18ie7_45.awsui_with-loading-bar_cdujn_18ie7_55:not(#\9) {
  padding-block-end: 0;
}
.awsui_message-area_cdujn_18ie7_45.awsui_chat-bubble-type-outgoing_cdujn_18ie7_58:not(#\9) {
  color: var(--color-text-chat-bubble-outgoing-bvbr0v, #0f141a);
  background-color: var(--color-background-chat-bubble-outgoing-pfqan2, transparent);
}
.awsui_message-area_cdujn_18ie7_45.awsui_chat-bubble-type-incoming_cdujn_18ie7_62:not(#\9) {
  color: var(--color-text-chat-bubble-incoming-x2kig8, #0f141a);
  background-color: var(--color-background-chat-bubble-incoming-8pi0ow, #f6f6f9);
}

.awsui_avatar_cdujn_18ie7_67:not(#\9) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_avatar_cdujn_18ie7_67.awsui_hide_cdujn_18ie7_70:not(#\9) {
  opacity: 0;
}

.awsui_content_cdujn_18ie7_74:not(#\9) {
  /* Used in test utils */
}

.awsui_actions_cdujn_18ie7_78:not(#\9) {
  /* Used in test utils */
}