/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
.awsui_root_pac4k_1b0pe_11:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
}

@keyframes awsui_searchbar_pac4k_1b0pe_1 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
@keyframes awsui_searchbar-rtl_pac4k_1b0pe_1 {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9),
.awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9) {
  height: 2px;
  background: var(--color-background-loading-bar-gen-ai-7qfi9s, linear-gradient(90deg, #99f7ff 0%, #0096fa 10%, #bf80ff 24%, #7300e5 50%, #bf80ff 76%, #0096fa 90%, #99f7ff 100%));
  background-size: 200% 100%;
  background-position: 0 100%;
  animation: awsui_searchbar_pac4k_1b0pe_1 3s cubic-bezier(0, 0.08, 1, 0.82) infinite forwards, var(--motion-keyframes-fade-in-gpabjb, awsui-fade-in-35003c) var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-expressive-jz8pes, cubic-bezier(0.84, 0, 0.16, 1));
  /* stylelint-disable no-descending-specificity */
}
@media (prefers-reduced-motion: reduce) {
  .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9),
  .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9), .awsui-mode-entering .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9), .awsui-motion-disabled .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9), .awsui-mode-entering .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9):dir(rtl),
.awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9):dir(rtl) {
  background-position: -100% 100%;
  animation: awsui_searchbar-rtl_pac4k_1b0pe_1 3s cubic-bezier(0, 0.08, 1, 0.82) infinite forwards, var(--motion-keyframes-fade-in-gpabjb, awsui-fade-in-35003c) var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-expressive-jz8pes, cubic-bezier(0.84, 0, 0.16, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9):dir(rtl),
  .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9):dir(rtl) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9):dir(rtl), .awsui-mode-entering .awsui_variant-gen-ai_pac4k_1b0pe_55:not(#\9):dir(rtl), .awsui-motion-disabled .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9):dir(rtl), .awsui-mode-entering .awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9):dir(rtl) {
  animation: none;
  transition: none;
}

.awsui_variant-gen-ai-masked_pac4k_1b0pe_56:not(#\9) {
  -webkit-mask-image: linear-gradient(to right, transparent 0%, transparent 3%, black 30%, black 70%, transparent 97%, transparent 100%);
          mask-image: linear-gradient(to right, transparent 0%, transparent 3%, black 30%, black 70%, transparent 97%, transparent 100%);
}