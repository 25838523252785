$primary-color: #333;
$secondary-color: white;
$link-color: white;

.top-bar {
  background-color: $primary-color;
  color: $secondary-color;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .logo {
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  nav {
    ul {
      list-style: none;
      display: flex;

      li {
        margin-right: 20px;

        a {
          color: $link-color;
          text-decoration: none;
          font-size: 18px;
        }
      }
    }
  }
}
